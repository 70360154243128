import {
  createAssets,
  createCreative,
  deleteAd,
  editCreative,
  getAssetDetails,
  getAssets,
  getBwCreativeDetails,
  getCreativeAddons,
  getImageByUrn,
  getVisitorInsightAudience,
  refreshAds,
} from "@/apis/v1/assets";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import { filterDataAccordingToAllowedAdvertisers } from "@/modules/Advertiser/advertiser.validation";
import getDimentionsFromImageDataUrl from "@/utils/getDimentionsFromImageDataUrl";
import { getVideoDuration } from "@/utils/helpers";
import {
  createBulkLinkedinAssets,
  getAssetInfo,
  validateZipEntriesForDisplay,
  validateZipEntriesForLinkedin,
} from "@/utils/unzipFileUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  CreativeType,
  displayAdSizes,
  internalCreativeTypeToLinkedinCreativeTypeMapping,
  linkedinAdSizes,
} from "./CreateOrEditAsset/data";
import { ASSET_TYPES } from "./data";
import useImageLoaded from "@/hooks/useImageLoaded";
import { AllowedCreativeType } from "./CreateOrEditAsset/types";
import { Channel, ImageLoadingState } from "@/types";
import { convertImageToBase64 } from "@/utils/convertFileToBytes";
type Type = Channel | "ALL";
export const LIST_AD_CREATIVE = (
  type: Type,
  advertiserId?: number | null,
  page?: number,
  size?: number,
  adType?: number
) => ["list", "creative", type, advertiserId, page, size, adType];
export const LIST_AD_CREATIVE_DETAILS = (id: number, type: Channel) => [
  "list",
  "creative",
  id,
  type,
];
export const REFRESH_AD_CREATIVE = (id: number, type: Channel) => [
  "list",
  "creative",
  id,
  type,
  "refresh",
];
export function useCreateAdCreative() {
  return useMutation(createCreative);
}
export function useListAdCreative({
  queryParams,
  enabled,
  type,
}: {
  type: Type;
  queryParams?: {
    page?: number;
    size?: number;
    adType?: number;
    isPagination: boolean;
    advertiserId?: number | null;
  };
  enabled?: boolean;
}) {
  return useQuery(
    LIST_AD_CREATIVE(
      type,
      queryParams?.advertiserId,
      queryParams?.page,
      queryParams?.size,
      queryParams?.adType
    ),
    getAssets,
    {
      enabled,
      meta: {
        queryParams: {
          ...queryParams,
          channel: type,
        },
      },
      select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
    }
  );
}
export function useListInsightAudience({
  queryParams,
  enabled,
  type,
}: {
  type: Type;
  queryParams: {
    advertiserId?: number | null | undefined;
    executionType: any;
  };
  enabled: boolean;
}) {
  return useQuery(
    LIST_AD_CREATIVE(
      type,
      queryParams?.advertiserId,
      queryParams?.executionType
    ),
    getVisitorInsightAudience,
    {
      enabled,
      meta: {
        queryParams: {
          ...queryParams,
          channel: type,
          status: JSON.stringify(["completed", "processing"]),
        },
      },
      select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
    }
  );
}
export function useDetailAdCreative({
  id,
  type,
  queryParams,
}: {
  id: number;
  type: "linkedin" | "display";
  queryParams?: any;
}) {
  return useQuery(LIST_AD_CREATIVE_DETAILS(id, type), getAssetDetails, {
    meta: {
      id,
      type,
      queryParams,
    },
    select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
  });
}
export function useGetImageByUrn({
  id,
  queryParams,
}: {
  id: string;
  queryParams?: any;
}) {
  return useQuery(["creatives", "image", id], getImageByUrn, {
    meta: {
      id,
      queryParams,
    },
  });
}

export function useGetBwCreativeDetails({ id }: { id: string }) {
  return useQuery(["creatives", "bw", id], getBwCreativeDetails, {
    meta: {
      id,
    },
  });
}

export function useRefreshAds() {
  return useMutation(refreshAds);
}
export function useDeleteAds() {
  return useMutation(deleteAd);
}
export function useCreateAssets() {
  return useMutation(createAssets);
}

export function useGetCreativeAddons({ enabled }: { enabled?: boolean }) {
  return useQuery(["list", "creative", "addons"], getCreativeAddons, {
    enabled,
  });
}

export function useValidDimentions() {
  function checkValidDisplayAssetDimentions(dimention: string) {
    return displayAdSizes.includes(dimention);
  }
  function checkValidLinkedinAssetDimentions({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) {
    if (width === height) {
      return compareDientions({ width, height, type: "square" });
    }
    if (width > height) {
      return compareDientions({ width, height, type: "horizontal" });
    }
    if (width < height) {
      return compareDientions({ width, height, type: "vertical" });
    }
  }
  function compareDientions({
    width,
    height,
    type,
  }: {
    width: number;
    height: number;
    type: "horizontal" | "vertical" | "square";
  }) {
    return (
      width >= linkedinAdSizes[type].minWidth &&
      width <= linkedinAdSizes[type].maxWidth &&
      height >= linkedinAdSizes[type].minHeight &&
      height <= linkedinAdSizes[type].maxHeight
    );
  }
  return {
    checkValidDisplayAssetDimentions,
    checkValidLinkedinAssetDimentions,
    compareDientions,
  };
}

type ImageSource = {
  imageIsLoaded: boolean;
  loadedStatus: ImageLoadingState;
  image: any;
  isLoading: boolean;
  previewUrl?: string;
}[];

export function useThumbnail() {
  function handleImageSources(row: any): ImageSource {
    const data = [];
    if (row?.channel === "facebook") {
      data.push(loadImageFromUrl(row?.thumbnailUrl));
    } else if (row?.channel === "display" && !row?.CreativeTemplateDataValue) {
      if (row?.adId) {
        data.push(loadBeeswaxImage(row?.adId));
      }
    } else if (row.carouselCards) {
      row?.carouselCards.forEach((item: any) => {
        data.push(loadLinkedinImage(item?.linkedinAssetUrn, row.advertiserId));
      });
    } else if (row?.linkedinAssetUrn) {
      data.push(loadLinkedinImage(row?.linkedinAssetUrn, row.advertiserId));
    }
    return data;
  }

  function loadLinkedinImage(asset: string, advertiserId: number) {
    const { data, isLoading: imageLoading } = useGetImageByUrn({
      id: asset,
      queryParams: {
        advertiserId,
      },
    });
    const loadedStatus = useImageLoaded({
      src: data?.downloadUrl,
      srcLoading: imageLoading,
    });
    const imageIsLoaded = Boolean(loadedStatus === "loaded");
    return {
      imageIsLoaded,
      loadedStatus,
      image: data?.downloadUrl,
      isLoading: imageLoading,
    };
  }

  function loadBeeswaxImage(assetId: string) {
    const { data: creativeDetails, isLoading: creativeDetailsLoading } =
      useGetBwCreativeDetails({
        id: assetId,
      });

    const loadedStatus = useImageLoaded({
      src: creativeDetails?.thumbnailUrl,
      srcLoading: creativeDetailsLoading,
    });
    const imageIsLoaded = Boolean(loadedStatus === "loaded");
    return {
      imageIsLoaded,
      loadedStatus,
      image: creativeDetails?.thumbnailUrl,
      previewUrl: creativeDetails?.previewUrl,
      isLoading: creativeDetailsLoading,
    };
  }

  function loadImageFromUrl(url: string) {
    const loadedStatus = useImageLoaded({
      src: url,
      srcLoading: false,
    });
    const imageIsLoaded = Boolean(loadedStatus === "loaded");
    return {
      imageIsLoaded,
      loadedStatus,
      image: url,
      isLoading: false,
    };
  }
  return { handleImageSources };
}
export function useHandleSubmit() {
  const { setSnackbarState } = useSnackbarDispatch();
  const { checkValidDisplayAssetDimentions } = useValidDimentions();
  const { mutateAsync: createAsset } = useCreateAssets();
  const navigate = useNavigate();
  async function handleSubmitCreative({
    data,
    setLoading,
    key,
    isEdit = false,
    creativeId,
    creative,
  }: {
    data: any;
    setLoading: React.Dispatch<boolean>;
    key: "linkedin" | "display" | "facebook";
    isEdit?: boolean;
    creativeId?: number;
    creative?: any;
  }) {
    try {
      setLoading(true);
      const { file, creativeType } = data;
      if (creativeType === CreativeType.CAROUSAL) {
        await createCarousalAd({
          isEdit,
          creative,
          setLoading,
          key,
          data,
          creativeId,
        });
      } else if (
        creativeType === CreativeType.IMAGE ||
        creativeType === CreativeType.VIDEO ||
        creativeType === CreativeType.TEXT
      ) {
        await uploadMedia({
          file,
          isEdit,
          creative,
          setLoading,
          key,
          data,
          creativeId,
        });
      } else if (
        creativeType === CreativeType.MULTI_IMAGE ||
        creativeType === CreativeType.MULTI_VIDEO
      ) {
        await uploadZip({
          key,
          isEdit,
          setLoading,
          file,
          data,
          creative,
          creativeId,
        });
      } else if (creativeType === CreativeType.HTML) {
        await uploadHtml({
          key,
          isEdit,
          setLoading,
          file,
          data,
          creative,
          creativeId,
        });
      } else if (creativeType === CreativeType.JS_TAG) {
        await uploadJsTag({
          isEdit,
          creative,
          setLoading,
          key,
          data,
          creativeId,
        });
      } else {
        setSnackbarState({
          open: true,
          severity: "error",
          message: messages.invalidFileType,
        });
        setLoading(false);
        return;
      }
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.errMsg ?? messages.somethingWentWrong,
      });
    } finally {
      setLoading(false);
    }
  }

  async function uploadMedia({
    file,
    isEdit,
    creative,
    setLoading,
    key,
    data,
    creativeId,
  }: any) {
    const isImage = file?.type?.startsWith("image/");
    const isVideo = file?.type?.startsWith("video/");
    const mimeType = file?.type?.split("/")[0];

    const {
      name,
      introductoryText,
      clickUrl = "",
      callToAction,
      advertiserId,
      heading,
      description,
      creativeType,
      creativeAddons,
    } = data;

    const payload: any = {
      name,
      clickUrl,
      advertiserId,
      creativeType,
      type:
        key === "display"
          ? creativeType === CreativeType.IMAGE
            ? ASSET_TYPES.BANNER
            : ASSET_TYPES.VIDEO
          : key === "linkedin"
          ? internalCreativeTypeToLinkedinCreativeTypeMapping[
              creativeType as AllowedCreativeType
            ]
          : null,
    };
    let dimentions: any = {};

    if (file || (isEdit && creative?.assetInfo?.dimentions)) {
      if ([CreativeType.IMAGE, CreativeType.TEXT].includes(creativeType)) {
        dimentions = isEdit
          ? {
              width: creative.assetInfo.dimentions.width,
              height: creative.assetInfo.dimentions.height,
            }
          : await getDimentionsFromImageDataUrl(
              window.URL.createObjectURL(file)
            );

        if (key === "display") {
          const isValid = checkValidDisplayAssetDimentions(
            `${dimentions.width}x${dimentions.height}`
          );
          if (!isValid) {
            setSnackbarState({
              open: true,
              severity: "error",
              message: messages.invalidImageRatio,
            });
            setLoading(false);
            return;
          }
        }
      }

      payload.assetInfo = {
        fileSize: file?.size ?? creative.assetInfo.fileSize,
        fileName: file?.name ?? creative.assetInfo.fileName,
        type: file?.type ?? creative.assetInfo.type,
        mimeType: isEdit ? creative.assetInfo.mimeType : mimeType,
        ...(isImage ? { dimentions } : {}),
        ...(isVideo ? { duration: await getVideoDuration(file) } : undefined),
        ...(key === "display" ? { creativeAddons } : undefined),
      };
    }

    if (key === "linkedin") {
      if (callToAction) payload["callToActionButton"] = callToAction;
      payload["introductoryText"] = introductoryText;
      payload["description"] = description;
      payload["heading"] = heading;
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("advertiserId", advertiserId);
        formData.append("isVideo", `${isVideo}`);
        formData.append("fileSize", `${file.size}`);
        const creativeAsset = await createAsset({
          data: formData,
          type: "linkedin",
        });
        payload["linkedinAssetUrn"] = isVideo
          ? creativeAsset.value.video
          : creativeAsset.value.image;
      }
    } else if (key === "display" && file) {
      const formData = new FormData();
      formData.append("file", file, file.name.replace(/\s/g, ""));
      formData.append("advertiserId", advertiserId);
      formData.append("click_url", clickUrl || "");
      const data = await createAsset({ data: formData, type: "display" });
      payload["assetId"] = data.id;
    } else if (key === "facebook" && file) {
      payload["description"] = description;
      const formData = new FormData();
      if (isImage) {
        formData.append("advertiserId", advertiserId);
        const imageBytes = await convertImageToBase64(file);
        if (imageBytes) formData.append("imageBytes", imageBytes);
        else {
          setSnackbarState({
            open: true,
            severity: "error",
            message: messages.invalidFileType,
          });
          setLoading(false);
          return;
        }
        const data = await createAsset({ data: formData, type: "facebook" });
        payload["imageHash"] = data.hash;
      } else if (isVideo) {
        formData.append("file", file, file.name.replace(/\s/g, ""));
        formData.append("advertiserId", advertiserId);
        const creativeAsset = await createAsset({
          data: formData,
          type: "facebook",
        });
        payload["videoId"] = creativeAsset.id;
      }
    }

    if (isEdit && creativeId) {
      await editCreative({
        payload,
        type: key,
        id: creativeId,
      });
    } else {
      await createCreative({
        payload,
        type: key,
      });
    }

    setLoading(false);
    setSnackbarState({
      open: true,
      message: messages.createdSuccessfully("Asset"),
    });
    navigate(-1);
  }
  async function createCarousalAd({
    file,
    isEdit,
    creative,
    setLoading,
    key,
    data,
    creativeId,
  }: any) {
    const {
      name,
      introductoryText,
      clickUrl = "",
      advertiserId,
      heading,
      cards = [],
      creativeType,
    } = data;
    cards.forEach((item: any) => {
      const isImage = item?.file?.type?.startsWith("image/");
      if (!isImage)
        setSnackbarState({
          open: true,
          severity: "error",
          message: "invalid",
        });
    });

    const uploadedSignedUrlsPromises: any[] = cards.map(
      (item: any) =>
        new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("image", item.file);
          formData.append("advertiserId", advertiserId);
          formData.append("fileSize", `${item.file.size}`);
          createAsset({ data: formData, type: "linkedin" })
            .then(async (data) => {
              const dimentions = await getDimentionsFromImageDataUrl(
                window.URL.createObjectURL(item.file)
              );
              resolve({
                linkedinAssetUrn: data.value.image,
                mimeType: item?.file?.type?.split("/")[0],
                heading: item?.heading,
                clickUrl: item?.clickUrl,
                fileSize: item?.file.size,
                fileName: item?.file.name,
                type: item?.file.type,
                dimentions,
              });
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
    );
    const uploadedSignedUrls = await Promise.all(uploadedSignedUrlsPromises);

    const payload: any = {
      name,
      clickUrl,
      advertiserId,
      type: internalCreativeTypeToLinkedinCreativeTypeMapping[
        data.creativeType as AllowedCreativeType
      ],
      carouselCards: uploadedSignedUrls,
      introductoryText,
      heading,
    };

    if (isEdit && creativeId) {
      await editCreative({
        payload,
        type: key,
        id: creativeId,
      });
    } else {
      await createCreative({
        payload,
        type: key,
      });
    }

    setLoading(false);
    setSnackbarState({
      open: true,
      message: messages.createdSuccessfully("Asset"),
    });
    navigate(-1);
  }
  async function uploadJsTag({
    isEdit,
    creative,
    setLoading,
    key,
    data,
    creativeId,
  }: any) {
    const {
      name,
      advertiserId,
      creativeType,
      dimensions,
      adServerMacros: creativeRuleKey,
      creativeContentTag,
      creativeAddons,
    } = data;
    const [width, height] = dimensions.split("x");

    const payload: any = {
      name,
      advertiserId,
      creativeType,
      type: isEdit ? creative.type : ASSET_TYPES.BANNER,
      creativeContentTag,
      creativeRuleKey,
      assetInfo: {
        dimentions: {
          width,
          height,
        },
        creativeAddons,
      },
    };
    if (isEdit && creativeId) {
      await editCreative({
        payload,
        type: key,
        id: creativeId,
      });
    } else {
      await createCreative({
        payload,
        type: key,
      });
    }

    setLoading(false);
    setSnackbarState({
      open: true,
      message: messages.createdSuccessfully("Asset"),
    });
    navigate(-1);
  }

  async function uploadZip({
    key,
    isEdit,
    setLoading,
    file,
    data,
    creativeId,
  }: any) {
    const isZip = file?.type?.split("/")[1].includes("zip");

    if (!isZip) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: messages.invalidFileType,
      });
      setLoading(false);
    }

    if (!isEdit) {
      let errorRes: any = {};
      if (key === "display") {
        errorRes = await validateZipEntriesForDisplay(file);
      } else {
        errorRes = await validateZipEntriesForLinkedin(file);
      }

      if (errorRes.isError) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: errorRes.message,
        });
        setLoading(false);

        return;
      }
    }

    const {
      introductoryText,
      clickUrl = "",
      callToAction,
      advertiserId,
      heading,
      description,
      creativeType,
      name,
    } = data;
    const payload: any = {
      name,
      clickUrl,
      advertiserId,
      creativeType,
      type:
        key === "display"
          ? creativeType === CreativeType.IMAGE
            ? ASSET_TYPES.BANNER
            : ASSET_TYPES.VIDEO
          : key === "linkedin"
          ? internalCreativeTypeToLinkedinCreativeTypeMapping[
              creativeType as AllowedCreativeType
            ]
          : null,
    };
    let bulkManifest: any = [];

    if (key === "linkedin") {
      payload["callToActionButton"] = callToAction;
      payload["introductoryText"] = introductoryText;
      payload["description"] = description;
      payload["heading"] = heading;
      const fileListResponse = await createBulkLinkedinAssets(
        file,
        advertiserId,
        createAsset
      );
      bulkManifest = fileListResponse;
    } else if (key === "display") {
      const formData = new FormData();
      formData.append("file", file, file.name.replace(/\s/g, ""));
      formData.append("advertiserId", advertiserId);
      formData.append("click_url", clickUrl || "");
      const data = await createAsset({ data: formData, type: "display" });
      payload["bulkAssetId"] = data.id;
      bulkManifest = data.manifest;
    }

    if (!isEdit) {
      if (key === "display") {
        uploadBulkToDisplay({ bulkManifest, file, key, payload });
      } else if (key === "linkedin") {
        uploadBulkToLinkedin({ bulkManifest, file, key, payload });
      }
    } else {
      if (creativeId) {
        await editCreative({
          payload,
          type: key,
          id: creativeId,
        });
      }
    }
    setSnackbarState({
      open: true,
      message: messages.createdSuccessfully("Asset"),
    });
    navigate(-1);
  }

  async function uploadHtml({
    file,
    isEdit,
    creative,
    setLoading,
    key,
    data,
    creativeId,
  }: any) {
    const isHtml = file?.type?.endsWith("html");
    const mimeType = file?.type?.split("/")[0];

    if (!isHtml) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: messages.invalidFileType,
      });
      setLoading(false);
      return;
    }

    const {
      name,
      clickUrl = "",
      advertiserId,
      creativeType,
      creativeAddons,
    } = data;

    const payload: any = {
      name,
      clickUrl,
      advertiserId,
      creativeType,
      type: isEdit ? creative.type : ASSET_TYPES.BANNER,
      assetInfo: {
        fileSize: file?.size ?? creative.assetInfo.fileSize,
        fileName: file?.name ?? creative.assetInfo.fileName,
        type: file?.type ?? creative.assetInfo.type,
        mimeType: isEdit ? creative.assetInfo.mimeType : mimeType,
        ...(key === "display" ? { creativeAddons } : undefined),
      },
    };

    if (key === "display") {
      const formData = new FormData();
      formData.append("file", file, file.name.replace(/\s/g, ""));
      formData.append("advertiserId", advertiserId);
      formData.append("click_url", clickUrl || "");
      const data = await createAsset({ data: formData, type: "display" });
      payload["assetId"] = data.id;
    }

    if (isEdit && creativeId) {
      await editCreative({
        payload,
        type: key,
        id: creativeId,
      });
    } else
      await createCreative({
        payload,
        type: key,
      });
    setSnackbarState({
      open: true,
      message: messages.createdSuccessfully("Asset"),
    });
    navigate(-1);
  }

  async function uploadBulkToDisplay({
    bulkManifest,
    file,
    key,
    payload,
  }: any) {
    const promiseList = [];
    for (let i = 0; i < bulkManifest.length; i++) {
      const zipEntryInfo = await getAssetInfo(
        key,
        file,
        bulkManifest[i].creative_name
      );
      promiseList.push(
        createCreative({
          payload: {
            ...payload,
            adId: bulkManifest[i].id,
            assetId: bulkManifest[i].id,
            type: ASSET_TYPES.BANNER,
            name: bulkManifest[i].creative_name,
            assetInfo: {
              mimeType: "image",
              dimentions: zipEntryInfo.dimentions,
              type: file?.type,
              fileSize: zipEntryInfo.fileSize,
              fileName: zipEntryInfo.fileName,
              duration: zipEntryInfo.duration,
            },
          },
          type: key,
        })
      );
    }
    await Promise.all(promiseList);
  }

  async function uploadBulkToLinkedin({
    key,
    file,
    bulkManifest,
    payload,
  }: any) {
    const promiseList = [];
    for (let i = 0; i < bulkManifest?.assetsPromiseList?.length; i++) {
      const zipEntryInfo = await getAssetInfo(
        key,
        file,
        bulkManifest.assetNames[i]
      );
      const creativePayload = {
        ...payload,
        linkedinAssetUrn:
          bulkManifest.assetsPromiseList[i].value.image ||
          bulkManifest.assetsPromiseList[i].value.video,
        name: zipEntryInfo.fileName,
        assetInfo: {
          mimeType: bulkManifest.assetsPromiseList[i].value.image
            ? "image"
            : "video",
          dimentions: zipEntryInfo.dimentions,
          fileSize: zipEntryInfo.fileSize,
          fileName: zipEntryInfo.fileName,
          duration: zipEntryInfo.duration,
        },
      };
      promiseList.push(
        createCreative({
          payload: creativePayload,
          type: key,
        })
      );
    }
    await Promise.all(promiseList);
  }

  return {
    handleSubmitCreative,
  };
}
