import CustomIconPopover from "@/components/Popover/CustomIconPopover";
import { formFieldError, Svg } from "@/types";
import labelRequired from "@/utils/labelRequired";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";
import { ExtraButtonsWithInputLabel } from "../../InputFields/inputfields.interface";

export default function CustomFormControl({
  label = null,
  children,
  required = false,
  disabled = false,
  error,
  toolTip = null,
  extraButtons,
  popOver = null,
  popOverIcon = null,
  characterCount,
}: CustomFormControlProps): ReturnType<React.FC> {
  return (
    <FormControl fullWidth disabled={disabled}>
      {label && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {labelRequired(label, required)}
          <Box sx={{ display: "flex" }}>
            {characterCount?.totalCount ? (
              <CharacterCount {...characterCount} />
            ) : null}
            {toolTip && (
              <Tooltip title={toolTip} placement="top-start">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {popOver && (
              <CustomIconPopover icon={popOverIcon}>
                {popOver}
              </CustomIconPopover>
            )}
            {extraButtons?.length &&
              extraButtons.map((buttonProps, index) => {
                const Icon = buttonProps.icon;
                return (
                  <Tooltip
                    key={index}
                    title={buttonProps.toolTipText}
                    placement="top-start"
                  >
                    <IconButton onClick={buttonProps.handleOnClick}>
                      <Icon />
                    </IconButton>
                  </Tooltip>
                );
              })}
          </Box>
        </InputLabel>
      )}
      {children}
      {error && <FormError error={error} />}
    </FormControl>
  );
}

export interface CustomFormControlProps {
  label?: string | null;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: formFieldError | string[];
  toolTip?: string | null;
  popOver?: React.ReactNode | null;
  popOverIcon?: Svg | null;
  extraButtons?: ExtraButtonsWithInputLabel[];
  characterCount?: {
    totalCount: number;
    currentCount: number;
  };
}

function FormError({ error }: { error: formFieldError | string[] }) {
  if (error && Array.isArray(error))
    return (
      <>
        {error.map((err, index) => (
          <FormHelperText key={index} error>{`${err}`}</FormHelperText>
        ))}
      </>
    );

  return <>{error && <FormHelperText error>{`${error}`}</FormHelperText>}</>;
}

function CharacterCount({
  totalCount,
  currentCount,
}: {
  totalCount: number;
  currentCount: number;
}) {
  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="caption" color={"text.secondary"}>
          <Box
            component={"span"}
            sx={{ color: currentCount > totalCount ? "red" : "text.secondary" }}
          >
            {currentCount}
          </Box>
          /{totalCount}
        </Typography>
      </Box>
    </Box>
  );
}
