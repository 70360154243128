const alertPromptValues = {
  0: "Are you sure?",
  1: "Do you wish to continue?",
  2: "Are you certain?",
};
export const messages = {
  minimumValues: (value?: string) =>
    `At least one ${value ? value : "value"} must be selected`,
  requiredField: "This is a required field",
  createdSuccessfully: (model: string) => `${model} created successfully`,
  copiedSuccessfully: `Copied`,
  updatedSuccessfully: (model: string) => `${model} updated successfully`,
  deletedSuccessfully: (model: string) => `${model} deleted successfully`,
  connectChannel: (channel: string) => `Please connect your ${channel} account`,
  channelLinkedWithAdvertiser: (channel: string) =>
    `Advertiser is not linked ${channel} account`,
  actionSuccessfully: (model: string, action: string) =>
    `${model} ${action} successfully`,
  dateError: "The value must be a date (mm-dd-yyyy)",
  minimumStartDate:
    "Start Date must be the same as or later than the campaign's start date.",
  endDateBeforeStart: "End date must come after start date",
  actionAlreadyRunning: (model: string) => `${model} is already running`,
  urlsRequired: "You must add a url",
  invalidUrl: "This url is not valid",
  somethingWentWrong: "something went wrong",
  alreadyExists: (model: string) => `${model} already exists!`,
  permissionDenied: "You don't have permission to do this action!",
  invalidImageRatio: "Please upload valid image resolution",
  invalidZipEntryType: "Invalid zip file entry format!",
  invalidVideoSize: "Please upload valid video size (.max 400000 bytes)",
  disconnectChannel: "Contact the website admin to perform this action!",
  invalidFileType: "Selected file is not a valid type!",
  selectFile: "Please select a file",
  noFilter: "No filter is selected",
  filterReset: "Your selected filters will reset",
  settingReset: (value: string) => `Your ${value} settings will reset`,
  alertPrompt: (x = 0) =>
    alertPromptValues[x as keyof typeof alertPromptValues],
  delete: (value: string) => `This ${value} will be deleted permanently.`,
  accountNotIntegrated: "Account is not integrated with any platform",
  exportContactSearch:
    "Search is uploaded successfully and parsing it in the background, it might take a few minutes for large files",
  backgroundSearch: "Search is running in the background!",
  userModuleAccess: (moduleName: string) =>
    `The user has access to ${moduleName.toUpperCase()} module.`,
  passwordNotMatching: "Passwords do not match.",
  confirmPasswordNotAllowed:
    "Password field is empty please remove value or add a password.",
  userTypeMust: "You must select a type of user you wish to create.",
  notAuthorized: "You are not authorized to make this change",
  noCRM: "You haven't integrated CRM, Stats will be limited",
  noTag:
    "Install our site visitor tag today to unlock website visitor insights!",
  issueWithTag:
    "No data found. Possible reasons: Tag Installation Issue or no Site Visitors.",
  forgetPassword:
    "Check your inbox for password reset instructions. If not received, check spam, and ensure you used the correct email address.",
  invalidCredentials: "Invalid credentials",
  missingAuthentication: "Missing authentication",
  noBeeswaxListItems: "There are currently no items in this List.",
  exceedsMaxLengthLimit: "Input exceeds the maximum allowed length",
  systemGeneratedReportSetup:
    "This report setup is automatically generated for the selected line item, and therefore, some fields are read-only.",
  expiredToken: "Expired token",
  displayNotConnected: "Display is not connected",
};
